.tags:hover{
    background-color:rgb(240, 245, 255) ;
}
.tagsdark:hover{
    background-color: rgba(255, 255, 255, 0.5);
}
.lightactive{
    background-color:rgb(240, 245, 255) ;
    /* font-weight: 800; */
}
.darkactive{
    background-color: rgba(255, 255, 255, 0.5);
    /* font-weight: 800; */
}
.z-100{
    z-index: 100;
}