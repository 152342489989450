@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Flex', sans-serif;
  font-size: 14px;
}

p {
  font-size: 12.5px;
  font-weight: 500;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
  border-radius: 10px;
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  height: 9px;
  width: 7.75px;
  background-color: rgb(255, 255, 255);
}

*::-webkit-scrollbar-thumb {
  border-radius: 15px;
  -webkit-box-shadow: inset 0 0 6px #E7E7ED;
  background-color: rgb(177, 177, 177);
}

.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: 15px;
  margin-top: -15px
}

.leftbar {
  width: 13%;
  min-width: 230px
}

@media screen and (max-width:1248px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .leftbar {
    min-width: 200px;
  }
}



@media screen and (max-width:762px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width:762px) {
  .hidelogo {
    display: none;
  }

  .left {
    max-width: 85px;
    margin-left: 15px;
    justify-content: center;
  }

}

@media screen and (max-width:504px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
    align-content: center;
  }

  .leftbar {
    display: block;
  }
}

.pagel {
  display: flex;
  flex-direction: row;
  height: 80vh
}

@media screen and (max-width:639px) {
  .pagel {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .smallpara{
    display: none;
  }
}

.MuiInputBase-root {
  height: 53px;
}

.MuiTablePagination{
  display: none !important;
}
.MuiTablePagination-select{
  display: none !important;
}
.MuiTablePagination-selectLabel{
  display: none !important;
}
.MuiTablePagination-selectIcon {
  display: none !important;
}

input[type=text] {
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
}

.text-plain{
  text-decoration: none;
  color: rgb(71, 71, 71);
  font-size: 14px;
}

.MuiListItemIcon-root{
  min-width: 30px !important;
}